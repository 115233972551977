<template>
  <List el="md">
    <List el="xsm">
      <Text el="h2">Sign In Methods</Text>
    </List>
    <List el="sm" style="max-width:600px;">
    <!-- <Text el>
        Click a method below to link a new sign in.
      </Text> -->
      <!-- <Icon name="west" style="font-size: 25px;" @click="billing.cardCount--" /> -->
      <div el="list-xxsm" class="providerList">
        <List el="column-between xsm p-sm" v-for="item in providerDataInfo" :key="item.uid" style="align-items:center;">
          <List el="column sm">
            <img :src="providerURL(item.providerId)" alt="Provider Profile Image" class="providerImg">
            <List el="xxsm" style="overflow:hidden;">
              <Text el="h4">{{item.displayName}}</Text>
              <Text>{{item.email}}</Text>
            </List>
          </List>
          <Icon name="delete" style="font-size: 25px;" @click="unlinkProvider(item.providerId)" />
        </List>
      </div>
      <hr el>
      <Text el="h3">Connect Account</Text>
      <List el="column xxsm" class="providerIcons">
        <div><img :src="providerURL('apple.com')" @click="linkProvider('apple.com')" alt="Apple" /></div>
        <div><img :src="providerURL('google.com')" @click="linkProvider('google.com')" alt="Google" /></div>
        <div><img :src="providerURL('microsoft.com')" @click="linkProvider('microsoft.com')" alt="Microsoft" /></div>
      </List>
    </List>
  </List>
</template>
<script setup>
import { reactive, ref } from 'vue';
import dialog from "@/components/dialog.js";
import { getAuth, linkWithPopup, OAuthProvider, unlink } from "firebase/auth";
import Icon from "@/components/btn/Icon.vue";

const auth = getAuth();
const { uid, providerData } = auth.currentUser;
const providerDataInfo = ref(providerData);

const providerURL  = (providerId) => {
  let r = null;
  switch(providerId){
    case 'google.com':
      r = '/Assets/Icons/google.webp';
      break;
    case 'apple.com':
      r = '/Assets/Icons/apple.svg';
      break;
    case 'microsoft.com':
      r = '/Assets/Icons/microsoft.svg';
      break;
  }
  return r;
}

const linkProvider  = (providerId) => {
  const provider = new OAuthProvider(providerId);
  linkWithPopup(auth.currentUser, provider).then((result) => {
    // Accounts successfully linked.
    // const credential = GoogleAuthProvider.credentialFromResult(result);
    const user = result.user;
    providerDataInfo.value = auth.currentUser.providerData;
    dialog.title('Success').alert('We connected your new sign in method!');
    // ...
  }).catch((error) => {
    // Handle Errors here.
    console.log(error);
    dialog.alert('Error: Unable to connect this account.');
  });
}
const unlinkProvider  = async (providerId) => {
  let confirm = await dialog.title('Delete Sign In Method').okText('Confirm').prompt(`Are you sure you want to delete your "${providerId}" sign in method? Deleting this sign in method prevents you from signing in using that account. To confirm, please type "delete sign in method"`);
  if (confirm == 'delete sign in method') {
    if(auth.currentUser.providerData.length > 1){
      const provider = new OAuthProvider(providerId);
      unlink(auth.currentUser, providerId).then(() => {
        // Auth provider unlinked from account
        providerDataInfo.value = auth.currentUser.providerData;
        dialog.title('Success').alert('We removed your existing sign in method.');
      }).catch((error) => {
        // An error happened
        console.log(error);
        dialog.alert('Error: Unable to remove this account.');
      });
    }else{
      dialog.alert('Error: You must keep at least one sign in method.');
    }
  }else{
    if(confirm === false) return;
    dialog.alert('Error: We are unable to delete since the confirm phrase was typed incorrectly.');
  }
  
}

</script>
<style lang="scss" scoped>
.searchInput{
  padding:0px 16px;
  height:45px;
  border-radius:80px;
  --bg-color:white;
  box-shadow: 0 10px 20px hsla(0, 0%, 0%, 0.07);
}
.smallTxt{
  font-size:12px;
  font-style: italic;
}
.billingHistoryList{
  max-width:700px;
}
.transaction{
  border-radius:8px;
  --bg-color:white;
  box-shadow: 0 10px 20px hsla(0, 0%, 0%, 0.07);
}



.providerImg{
  height: 100%;
    width: 60px;
    object-fit: contain;
    object-position: center;
}
.providerList > *{
  --bg-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  user-select: none;
  white-space: nowrap;
  box-shadow: 0 10px 20px hsla(0, 0%, 0%, 0.07);
}
.providerIcons > *{
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  padding:12px;
  display: grid;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  box-shadow: 0 10px 20px hsla(0, 0%, 0%, 0.07);
}
.providerIcons img{
  height: 45px;
    width: 45px;
    object-fit: contain;
    object-position: center;
}
</style>
