<template>
  <List el="column-alt lg">

  <List el="lg" style="max-width: 1200px">
    <List el="column-between lg">
      <Text el="h2">Settings</Text>
      <!-- <Btn
        @click="to('/dashboard/recordings/upload')"
        v-if="$store.getters.isAdmin"
        >Create</Btn
      > -->
    </List>
    <div el="list xxsm" class="notificationList">
      <Text el="bold">Notifications</Text>
      <div el="list-column-between sm" v-for="item in settings.notificationTypes" class="toggleSetting" @click="item.value = !item.value">
        <Text>{{item.name}}</Text>
        <div el="toggle" :class="{active: item.value == true}"></div>
      </div>
    </div>
    <div el="list xxsm" class="notificationList">
      <Text el="bold">Notification Methods</Text>
      <div el="list-column-between sm" v-for="item in settings.notificationMethods" class="toggleSetting" @click="item.value = !item.value">
        <Text>{{item.name}}</Text>
        <div el="toggle" :class="{active: item.value == true}"></div>
      </div>
    </div>
  </List>
  <SignInMethods />
</List>
</template>
<script setup>
import { reactive } from "vue";
import router from "@/router";
import SignInMethods from "../components/SignInMethods.vue";

let settings = reactive({
  notificationTypes:[
  {
  name:'New Charges',
  value: true,
},
  {
  name:'Credit Card Updates',
  value: true,
},
  {
  name:'Account Changes',
  value: true,
},
  {
  name:'New Features',
  value: true,
},
],
notificationMethods:[
{
  name:'Email',
  value: true,
},
{
  name:'Text Message',
  value: true,
},
{
  name:'Dashboard',
  value: true,
},
],
});
// let filter = require("lodash/filter");
// let lowerCase = require("lodash/lowerCase");
// let includes = require("lodash/includes");
// import BillingHistory from "../components/BillingHistory.vue";


const to = (path)=> {
  // this.$store.commit("collapseSection", false);
  router.push(path);
};
  
</script>
<style lang="scss" scoped>
.notificationList{
  max-width: 300px;
}
.toggleSetting{
  align-items: center;
  --bg-color:white;
  padding: 8px;
  padding-left:16px;
  border-radius: 100px;
  box-shadow: 0 10px 20px hsla(0, 0%, 0%, 0.07);
  cursor: pointer;
  user-select: none;
}
</style>
